.content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  z-index: 1;
}

.bgPicture {
  width: 100%;
  height: 200px;
  display:block;
  background: #232a34;
  filter:brightness(0.15);
  transform: scale(2);
  background-size: cover;
  background-repeat: no-repeat;
}

.bgPictureContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  z-index: 1;
  
}
.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.contentOver{
  display: block;
  background-color: #252525;
  width: 100%;
  margin-top: 1000px;
  height: 1200px;
  z-index:9999;
}
.pageTitle{
  left: 45%;
  top: 20%;
  position: absolute;
  z-index: 2345;

  color: #fff; /* Adjust color as needed */

}