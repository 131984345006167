body{
  height: 100%;
  overflow-y: hidden;
}
.bgPicture {
  width: 100%;
  height: 100%;
  display:block;
  background: #232a34;
  filter:brightness(0.15);
  transform: scale(2);
  background-size: cover;
  background-repeat: no-repeat;
}

.bgPictureContainer {
  position: absolute;
  top:0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  
}
.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  z-index: 1;
  
}

.middlecontainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  
}
@media screen and (max-width: 1000px) {

  .middlecontainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  }
  .bgPicture {
    width: 100%;
    height: 100%;
    display:block;
    background: #232a34;
    filter:brightness(0.15);
    transform: scale(2.5);
    background-size: cover;
    background-repeat: no-repeat;
  }
}
@media screen and (max-width: 700px) {
  .bgPicture {
    width: 100%;
    height: 100%;
    display:block;
    background: #232a34;
    filter:brightness(0.15);
    transform: scale(3.5);
    background-size: cover;
    background-repeat: no-repeat;}
}
@media screen and (max-width: 500px) {
  .bgPicture {
    width: 100%;
    height: 100%;
    display:block;
    background: #232a34;
    filter:brightness(0.15);
    transform: scale(5);
    background-size: cover;
    background-repeat: no-repeat;}
}
@media screen and (max-width: 350px) {
  .bgPicture {
    width: 100%;
    height: 100%;
    display:block;
    background: #232a34;
    filter:brightness(0.15);
    transform: scale(8);
    background-size: cover;
    background-repeat: no-repeat;}
}
@media screen and (max-width: 220px) {
  .bgPicture {
    width: 100%;
    height: 100%;
    display:block;
    background: #232a34;
    filter:brightness(0.15);
    transform: scale(20);
    background-size: cover;
    background-repeat: no-repeat;}
}

.content {
  z-index: 3;
  width: 50%;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  background: none;
  text-align: left;
  line-height: 1.8;
}
.corperateimagecontainer{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

}
@media screen and (min-width: 1000px) {
  .corperateimagecontainer{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;

  }
}
.corperateimage{
  width: 100%;
 
  animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}
@keyframes floating {
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, 15px); }
  100%   { transform: translate(0, -0px); }   
}

.content h1 {
  color: #fff;
  font-size: 48px;
  @media screen and (max-width: 768px) {
    font-size: 40px;
  }
  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
}

.content p {
  margin-top: 24px;
  color: #fff;
  font-size: 24px;
 
  max-width: 600px;
  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
}
