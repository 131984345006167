.header {
  background-color: #252525;
  height: 70px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 100;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 9999;
}

.title {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  text-decoration: none;
  font-size: 25px;
  margin-left: 50px;
}

.mainheadercontent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 15%;
}

.headercontent {
  display: flex;
  justify-content: center;
  font-size: 18px;
  min-width: 150px;
  text-decoration: none;
  color: #fff;
}
.miniheader {
  display: none;
}
.mobilemodal {
  display: none;
}
.mobilemodal .headercontent {
  display: none;
}
@media screen and (max-width: 1000px) {
  .mainheadercontent {
    display: none;
  }
  .buttonwrapper {
    display: none !important;
  }
  .contact {
    display: none;
  }

  .mobilemodal .headercontent {
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 18px;
    min-width: 150px;
    text-decoration: none;
    z-index: -100;
  }
  .miniheader {
    display: block;
    margin-right: 20px;
    cursor: pointer;
  }

  .mobilemodal {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100vw;
    background-color: #353535;
    z-index: -100;
  }
}
.visiblemobilemodal {
  transition:
    ease-in-out 0.5s,
    height 0.5s;
  height: 170px;
  display: flex;
  margin-top: 230px;
  z-index: -100;
}
.visiblemobilemodal .headercontent {
  transition:
    ease-in-out visibility 1s,
    opacity 0.3s,
    padding 0.5s;
  visibility: visible;
  opacity: 1;
  padding: 10px;
}

.hiddenmobilemodal {
  transition: ease-in-out 0.5s;
  height: 0px;
}
.hiddenmobilemodal .headercontent {
  transition:
    ease-in-out visibility 0.3s,
    opacity 0.3s;
  visibility: hidden;
  opacity: 0;
}
/* 
.headercontent2 {
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 23px;

  min-width: 150px;
}

.headercontent3 {
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 23px;

  min-width: 150px;
} */

.contact {
  background-color: #d9241c;
  color: #000;
  font-size: 18px;
  background: none;
  border: solid 1px #d9241c;
}

.buttonwrapper {
  background-color: #d9241c;
  border-radius: 50px;
  width: 180px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin-right: 50px;
  cursor: pointer;
  border: solid 1px #d9241c;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.buttonwrapper :hover {
  background-color: #252525;
  border-radius: 50px;
  color: #fff;
  width: 180px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: 0.5s;
  animation-timing-function: ease-in-out;
}

.logo {
  width: 70px;
  height: 70px;
  cursor: pointer;
}
